import React, { useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CheckoutSummary from "../../Components/CheckoutSummary/CheckoutSummary";
import {
  selectEmail,
  selectID,
  selectUserName,
} from "../../Redux/Slice/authSlice";
import {
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  CLEAR_CART,
  selectCartItems,
  selectCartToatalAmount,
} from "../../Redux/Slice/cartSlice";
import {
  selectBillingAddress,
  selectShippingAddress,
} from "../../Redux/Slice/checkoutSlice";

import styles from "./Checkout.module.scss";
import { db } from "../../firebase/Config";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectCartToatalAmount);
  const userEmail = useSelector(selectEmail);
  const name = useSelector(selectUserName);
  const userID = useSelector(selectID);
  const shippingAddress = useSelector(selectShippingAddress);
  const billingAddress = useSelector(selectBillingAddress);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL());
    dispatch(CALCULATE_TOTAL_QUANTITY());
  }, [dispatch, cartItems]);

  // Save order to Order History
  const saveOrder = () => {
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();
    const orderConfig = {
      userID,
      userEmail,
      orderDate: date,
      orderTime: time,
      orderAmount: totalAmount,
      orderStatus: "Order Placed...",
      cartItems,
      shippingAddress,
      // shippingAddress: JSON.stringify(shippingAddress),
      createdAt: Timestamp.now().toDate(),
    };
    try {
      addDoc(collection(db, "orders"), orderConfig);
      dispatch(CLEAR_CART());
      toast.success("Order saved");
      navigate("/checkout-success");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className={styles.buttons}>
      {/* <CheckoutSummary/> */}
      <PaystackButton
        className={styles.paystack}
        email={userEmail}
        amount={totalAmount * 100}
        currency="USD"
        metaData={{ name }}
        publicKey="pk_test_9876a5216aa5caf77e4d8c2e014453a13e28ee5c"
        text="Pay with Paystack"
        onSuccess={() => {
          toast.success("You have Successfully bought our product");
          saveOrder();
          // navigate("/checkout-success");
        }}
        onClose={() => toast.error("Transaction was unsuccessful")}
      />

      <div></div>
    </div>
  );
};

export default Checkout;
