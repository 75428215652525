import image1 from "../../images/1.jpg"
import image2 from "../../images/2.jpg"
import image3 from "../../images/3.jpg"
import image4 from "../../images/4.jpg"

export const SliderData = [
  {
    image: image3,
    heading: "Women Fashion",
    desc: "Up to 30% off on all on-sale products.",
  },
  {
    image: image4,
    heading: "Shoes Villa",
    desc: "Up to 30% off on all on-sale products.",
  },
  {
    image: image1,
    heading: "Men Fashion",
    desc: "Up to 30% off on all on-sale products.",
  },
  {
    image: image2,
    heading: "Awesome Slides",
    desc: "Up to 30% off on all on-sale products.",
  },
];
