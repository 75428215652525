// Slider.js
import React, { useState } from "react";
import styles from "./Slider.module.scss";
import { Link } from "react-router-dom";

const Slider = ({ category, products, id }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);

  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + products.length) % products.length
    );

  };

  return (
    <div className={styles.slider}>
      <h2>{category}</h2>

<div className={styles["slider-container"]}>
  {products.map((product, index) => (
    <div key={product.id} className={styles["slider-item-wrapper"]}>
      <Link to={`/product-details/${product.id}`}>
        <div
          className={styles["slider-item"]}
          style={{
            transform: `translateX(-${currentIndex * (25 + 10)}%)`,
          }}
        >
          <img src={product.imageUrl} alt={product.name} />
          <p>{product.name}</p>
          <p>${`${product.price}`}</p>
          <p>{`${product.color}`}</p>


          {/* Size mapping */}
          <div className={styles.sizesContainer}>
            {product.sizes.map((size) => (
              <div
                key={size.id}
                className={`${styles.size} ${
                  size.selected ? styles.selected : ""
                }`}
              >
                {size}
              </div>
            ))}
          </div>
        </div>
      </Link>
    </div>
  ))}
</div>


      <div className={styles["slider-controls"]}>
        <button className={styles.prev} onClick={handlePrev}>
          {"<"}
        </button>
        <button className={styles.next} onClick={handleNext}>
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Slider;
