import styles from "./Contact.module.scss";

const Contact = () => {
  return (
    <>
      <div className={styles.contact}>
      <div className={styles.title}>

        <h1>Contact</h1>

        <p>
          Whether you’re looking for answers, would like to solve a problem, or
          just want to let us know what you think of our brand, you can chat
          with us via the balloon in the bottom right corner or email us at
          support@foxhilluniverse@gmail.com. We’ll help you resolve your issues
          quickly and easily.
        </p>
        </div>

      </div>
    </>
  );
};

export default Contact;
