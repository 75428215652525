import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from '../../../firebase/Config';
import { ADD_TO_CART, CALCULATE_TOTAL_QUANTITY, DECREASE_CART, selectCartItems } from '../../../Redux/Slice/cartSlice';
import Loader from '../../Loader/Loader';
import styles from './ProductDetails.module.scss'
import UseFetchCollection from '../../../customHook.js/useFetchCollection';
import Card from '../../card/Card';
import StarsRating from 'react-star-rate';
import useFetchDocument from '../../../customHook.js/useFetchDocument';

const ProductDetails = () => {
    const {id} = useParams()
    const [product, setProduct] = useState(null);
    const dispatch = useDispatch()

    const cartItems = useSelector(selectCartItems)

    const cart = cartItems.find((cart) => cart.id === id)
    const { data } = UseFetchCollection("reviews");
    const { document } = useFetchDocument("products", id);

    const filteredReviews = data.filter((review) => review.productID === id);



    useEffect(() => {
      setProduct(document);
    }, [document]);
  
  

    const  addTOCart = () => {
        dispatch(ADD_TO_CART(product))
        dispatch(CALCULATE_TOTAL_QUANTITY(product))

    }
    const  decreaseCart = () => {
        dispatch(DECREASE_CART(product))
        dispatch(CALCULATE_TOTAL_QUANTITY(product))

    }

    const isCartAdded = cartItems.findIndex((cart) => {
        return cart.id === id
    })

    
        

    const getProduct = async () => {
        const docRef = doc(db, "products", id);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  const obj = {
    id: id,
    ...docSnap.data()
    
  }
  setProduct(obj)
} else {
  toast.error('product not found')
}
  };
    useEffect(() => {
        getProduct()  

        }, []);

    return (

        <section>
        <div className={`containers ${styles.product}`}>
        <div className={` ${styles.titlehead}`}>

          <div>
                      </div>

          <div>
            <Link to="/#products">&larr; Back to products</Link>
          </div>
          </div>
          {product ? (
            <div className={styles.details}>
              <div className={styles.img}>
                <img src={product.imageUrl} alt={product.name} />
              </div>
              <div className={styles.title}>
                <h3>{product.name}</h3>
                <p className={styles.price}>{`$${product.price}`}</p>
                <p>Tax included. Shipping calculated at checkout</p>


                <h4>Color : {product.color}</h4>
                <h4>Sizes:</h4>
  <div className={styles.sizesContainer}>
    {product.sizes.map((size) => (
      <div key={size.id} className={`${styles.size} ${size.selected ? styles.selected : ''}`}>
        {size}
      </div>
    ))}
  </div>


                <p>{product.desc}</p>
                <p>
                  <b>{product.brand}</b>
                </p>


<div className={styles.count}>
    <p>Quantity   </p>
  <button className="--btn" onClick={decreaseCart}>-</button>
  <p><b>{cart ? cart.cartQuantity : 0}</b></p>
  <button className="--btn" onClick={addTOCart}>+</button>
</div>
<button className={styles.addtocart} onClick={addTOCart}>
  Add to Cart
</button>

              </div>
            </div>
          ) : (
            <Loader />
          )}

<Card cardClass={styles.card}>
          <h3>Product Reviews</h3>
          <div>
            {filteredReviews.length === 0 ? (
              <p>There are no reviews for this product yet.</p>
            ) : (
              <>
                {filteredReviews.map((item, index) => {
                  const { rate, review, reviewDate, userName } = item;
                  return (
                    <div key={index} className={styles.review}>
                      <StarsRating value={rate} />
                      <p>{review}</p>
                      <span>
                        <b>{reviewDate}</b>
                      </span>
                      <br />
                      <span>
                        <b>by: {userName}</b>
                      </span>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Card>

        </div>
      </section>
  
    );
}


export default ProductDetails;
