import { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../Components/card/Card";
import {
  SAVE_BILLING_ADDRESS,
  SAVE_SHIPPING_ADDRESS,
} from "../../Redux/Slice/checkoutSlice";
import styles from "./CheckoutDetails.module.scss";
import CheckoutSummary from "../../Components/CheckoutSummary/CheckoutSummary";
import PaymentForm from "./payment";
import React, { useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectEmail,
  selectID,
  selectUserName,
} from "../../Redux/Slice/authSlice";
import {
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  CLEAR_CART,
  selectCartItems,
  selectCartToatalAmount,
} from "../../Redux/Slice/cartSlice";
import {
  selectBillingAddress,
  selectShippingAddress,
} from "../../Redux/Slice/checkoutSlice";

import { db } from "../../firebase/Config";
import { Timestamp, addDoc, collection } from "firebase/firestore";

const initialAddressState = {
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  phone: "",
};

const CheckoutDetails = () => {
  const [shippingAddress, setShippingAddress] = useState({
    ...initialAddressState,
  });
  const [billingAddress, setBillingAddress] = useState({
    ...initialAddressState,
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [formValid, setFormValid] = useState(false); // State to manage form validity


  const dispatch = useDispatch();
  const navigate = useNavigate();

    // Validation function to check if all required fields are filled
    const validateForm = () => {
      return (
        shippingAddress.name !== "" &&
        shippingAddress.line1 !== "" &&
        shippingAddress.city !== "" &&
        shippingAddress.state !== "" &&
        shippingAddress.postal_code !== "" &&
        shippingAddress.country !== "" &&
        shippingAddress.phone !== "" &&
        billingAddress.name !== "" &&
        billingAddress.line1 !== "" &&
        billingAddress.city !== "" &&
        billingAddress.state !== "" &&
        billingAddress.postal_code !== "" &&
        billingAddress.country !== "" &&
        billingAddress.phone !== ""
      );
    };
  
  

  const handleShipping = (e) => {
    const { name, value } = e.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };

  const handleBilling = (e) => {
    const { name, value } = e.target;
    setBillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
    dispatch(SAVE_BILLING_ADDRESS(billingAddress));
    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));

    // navigate("/checkout");
  };



  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectCartToatalAmount);
  const userEmail = useSelector(selectEmail);
  const name = useSelector(selectUserName);
  const userID = useSelector(selectID);

  const dispatchThis = useDispatch();
  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL());
    dispatch(CALCULATE_TOTAL_QUANTITY());
  }, [dispatch, cartItems]);

  const saveOrder = () => {
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();
    const orderConfig = {
      userID,
      userEmail,
      orderDate: date,
      orderTime: time,
      orderAmount: totalAmount,
      orderStatus: "Order Placed...",
      cartItems,
      shippingAddress,
      // shippingAddress: JSON.stringify(shippingAddress),
      createdAt: Timestamp.now().toDate(),
    };
    try {
      addDoc(collection(db, "orders"), orderConfig);
      dispatchThis(CLEAR_CART());
      toast.success("Order saved");
      navigate("/checkout-success");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section>
      <div className={`container ${styles.checkout}`}>
        <form onSubmit={handleSubmit}>
          <div>
            <h1>Checkout Details</h1>

            <h4>Shipping Address</h4>
            <div className={styles.fullinput}>
              <input
                type="text"
                placeholder="Recipient Name"
                required
                name="name"
                value={shippingAddress.name}
                onChange={(e) => handleShipping(e)}
              />
            </div>
            <div className={styles.inputfex}>
              <input
                type="text"
                placeholder="Address line 1"
                required
                name="line1"
                value={shippingAddress.line1}
                onChange={(e) => handleShipping(e)}
              />
              <input
                type="text"
                placeholder="Address line 2"
                name="line2"
                value={shippingAddress.line2}
                onChange={(e) => handleShipping(e)}
              />
            </div>
            <div className={styles.fullinput}>
              <CountryDropdown
                className={styles.select}
                valueType="short"
                value={shippingAddress.country}
                onChange={(val) =>
                  handleShipping({
                    target: {
                      name: "country",
                      value: val,
                    },
                  })
                }
              />
            </div>
            <div className={styles.inputfex}>
              <input
                type="text"
                placeholder="City"
                required
                name="city"
                value={shippingAddress.city}
                onChange={(e) => handleShipping(e)}
              />
              <input
                type="text"
                placeholder="State"
                required
                name="state"
                value={shippingAddress.state}
                onChange={(e) => handleShipping(e)}
              />
              <input
                type="text"
                placeholder="Postal code"
                required
                name="postal_code"
                value={shippingAddress.postal_code}
                onChange={(e) => handleShipping(e)}
              />
            </div>
            <div className={styles.fullinput}>
              <input
                type="text"
                placeholder="Phone"
                required
                name="phone"
                value={shippingAddress.phone}
                onChange={(e) => handleShipping(e)}
              />
            </div>
            {/* BILLING ADDRESS */}

            <h3>Billing Address</h3>
            <div className={styles.fullinput}>
              <input
                type="text"
                placeholder="Name"
                required
                name="name"
                value={billingAddress.name}
                onChange={(e) => handleBilling(e)}
              />
            </div>
            <div className={styles.inputfex}>
              <input
                type="text"
                placeholder="Address line 1"
                required
                name="line1"
                value={billingAddress.line1}
                onChange={(e) => handleBilling(e)}
              />
              <input
                type="text"
                placeholder="Address line 2"
                name="line2"
                value={billingAddress.line2}
                onChange={(e) => handleBilling(e)}
              />
            </div>
            <div className={styles.fullinput}>
              {/* COUNTRY INPUT */}
              <CountryDropdown
                className={styles.select}
                valueType="short"
                value={billingAddress.country}
                onChange={(val) =>
                  handleBilling({
                    target: {
                      name: "country",
                      value: val,
                    },
                  })
                }
              />
            </div>
            <div className={styles.inputfex}>
              <input
                type="text"
                placeholder="City"
                required
                name="city"
                value={billingAddress.city}
                onChange={(e) => handleBilling(e)}
              />
              <input
                type="text"
                placeholder="State"
                required
                name="state"
                value={billingAddress.state}
                onChange={(e) => handleBilling(e)}
              />
              <input
                type="text"
                placeholder="Postal code"
                required
                name="postal_code"
                value={billingAddress.postal_code}
                onChange={(e) => handleBilling(e)}
              />
            </div>
            <div className={styles.fullinput}>
              <input
                type="number"
                placeholder="Phone"
                required
                name="phone"
                value={billingAddress.phone}
                onChange={(e) => handleBilling(e)}
              />
            </div>

            <div>
              <PaymentForm
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            {selectedOption === "paystack" ? (
              <div className={styles.buttons}>
                {/* <CheckoutSummary/> */}
                <PaystackButton
                  type="submit"
                  className={styles.paystack}
                  email={userEmail}
                   currency="USD"
                  amount={totalAmount * 100}
                  metaData={{ name }}
                  publicKey="pk_test_9876a5216aa5caf77e4d8c2e014453a13e28ee5c"
                  text="Pay with Paystack"
                  onSuccess={() => {
                    toast.success("You have Successfully bought our product");
                    saveOrder();
                    // navigate("/checkout-success");
                  }}
                  onClose={() => toast.error("Transaction was unsuccessful")}
                />

                <div></div>
              </div>
            ) : null}

            {selectedOption === "bankTransfer" ? (
              <div className="--btn --btn-primary">
                Transfer and reach us on WhatsApp
              </div>
            ) : null}
          </div>

          <div>
            <CheckoutSummary />
          </div>
        </form>
      </div>
    </section>
  );
};

export default CheckoutDetails;
