import { Route, Routes } from "react-router-dom";
import { Home, Cart, Contact, Admin } from "./Pages/Index";
import { Header, Footer } from "./Components/Index";
import Register from "./Pages/Auth/Register/Register";
import Login from "./Pages/Auth/Login/Login";
import ResetPassword from "./Pages/Auth/ResetPassword/ResetPassword";
import AdimnRoute from "./Components/AdminRoute/AdminRoute";
import ProductDetails from "./Components/Products/productDetails/ProductDetails";
import CheckoutDetails from "./Pages/Checkout/CheckoutDetails";
import Checkout from "./Pages/Checkout/Checkout";
import OrderHistory from "./Pages/OrderHistory/OrderHistory";
import ReviewProducts from "./Components/reviewProducts/ReviewProducts";
import OrderDetails from "./Pages/orderDetails/orderDetails";
import "./whatsapp.scss";
import CheckoutSuccess from "./Pages/Checkout/CheckoutSucccess";
import Shop from "./Pages/Shop/Shop";
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/prderhistory" element={<OrderHistory />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/shop" element={<Shop />} />
        <Route
          path="/admin/*"
          element={
            <AdimnRoute>
              <Admin />
            </AdimnRoute>
          }
        />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/checkout-details" element={<CheckoutDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />

        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/order-details/:id" element={<OrderDetails />} />
        <Route path="/review-product/:id" element={<ReviewProducts />} />
      </Routes>
      <Footer />
      <a
        href="https://wa.me/2349034042896"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <span class="help-text">Need help? Chat with us</span>
    </>
  );
}

export default App;
