import React, { useEffect, useState } from "react";
import UseFetchCollection from "../../../customHook.js/useFetchCollection";
import Slider from "./Slider";

import styles from "./ProductPreview.module.scss";

const ProductPreview = () => {
  const { data, isLoading } = UseFetchCollection("products");

  const categories = ["Shoes", "Tshirts", "Slides", "Hoodies"];


  return (
    <>
      <div className={styles.wrapper}>
        <h1>Amazing Collections</h1>
      
        <div>
          {categories.map((category) => (
            <Slider
              key={category}
              category={category}
              products={data.filter(
                (product) => product.category === category
              )}
            />
          ))}
        </div>
      
      </div>
    </>
  );
};

export default ProductPreview;
