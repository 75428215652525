import { useEffect } from "react";
import AdminRoute from "../../Components/AdminRoute/AdminRoute";
import Product from "../../Components/Products/Produts";
import Slider from "../../Components/Slider/Slider";
import ProductCategory from "../../Components/Products/categoryProducts/ProductCategory";
// import MainCategory from "../../Components/Products/categoryItem/mainCategory";
import ProductPreview from "../../Components/Products/ProductPreview/ProductPreview";
import styles from "./Home.module.scss";

const Home = () => {
  const url = window.location.href;

  const scrollToProducts = () => {
    if (url.includes("#products")) {
      window.scrollTo({
        top: "700",
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToProducts();
  }, [scrollToProducts]);

  return (
    <div className={`container ${styles.divider}`}>
      <Slider />
      <ProductCategory />
      <ProductPreview />
    </div>
  );
};

export default Home;
