// import  styles from './Footer.module.scss'
import styles from "./Footer.scss";

import { Link } from "react-router-dom";
import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import { FaFacebook, FaTwitter, FaDribbble, FaLinkedin, FaInstagram } from "react-icons/fa";

const date = new Date();
const year = date.getFullYear();


const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>Welcome to FoxHill Universe</h6>
            <p className="text-justify">
              Explore the vibrant world of Foxhill Universe, a proudly Nigerian
              eCommerce store where elegance meets tradition. We take pride in
              curating a collection that seamlessly blends the richness of
              Nigerian heritage with contemporary style
            </p>
          </div>


          <div className="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <a href="/shop">Shop</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <div className="footer-container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Copyright &copy; {year} All Rights Reserved by
              <a href="/"> FoxHill Universe</a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a className="facebook" href="/">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a className="twitter" href="/">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a className="dribble"  href="https://www.instagram.com/foxhilluniverse/"target="_blank">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a className="linkedin" href="/">
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
