import React, { useState } from "react";
import "./payment.scss";

const PaymentForm = ({selectedOption, setSelectedOption}) => {
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="payment-form">
      <h2>Payment</h2>
      <h4>All transactions are secure and encrypted.</h4>
      <div className="input-container">
        <input
          type="radio"
          id="paystack"
          name="paymentMethod"
          value="paystack"
          onChange={() => handleOptionChange("paystack")}
          checked={selectedOption === "paystack"}
        />
        <label htmlFor="paystack">Paystack</label>
      </div>
      {selectedOption === "paystack" && (
        <div className="dropdown paystack-dropdown">
          <img src="paystack-icon.png" alt="Paystack icon" />
          <p>
            After clicking “Pay now”, you will be redirected to Paystack to
            complete your purchase securely.
          </p>
        </div>
      )}

      <div className="input-container">
        <input
          type="radio"
          id="bankTransfer"
          name="paymentMethod"
          value="bankTransfer"
          onChange={() => handleOptionChange("bankTransfer")}
          checked={selectedOption === "bankTransfer"}
        />
        <label htmlFor="bankTransfer">Bank Transfer</label>
      </div>
      {selectedOption === "bankTransfer" && (
        <div className="dropdown bank-transfer-dropdown">
          <p>
            Make a Bank Transfer (In Nigerian NGN) of the required amount to the
            following account: Please note that our conversion rate is 1,200NGN
            to $1 and we will confirm all payments before processing your order.
            Name: ASHCORP LUXURY PROJECTS LTD - 2 Account Number: 1029783014
            Bank Name: VFD MFB Kindly reach out to +234 702 507 0045 with your
            detailed proof of payment for confirmation. Please note that we
            confirm all payments before processing orders.
          </p>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
